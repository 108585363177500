import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Ich nutze gerne die JavaScript-Bibliothek `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/idb"
      }}>{`idb`}</a>{`, einen minimalen Wrapper für
die `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API"
      }}>{`IndexedDB API`}</a>{`. Ich habe sie schon in mehreren
Web-Applikationen verwendet, um Offline-Features bereitzustellen.`}</p>
    <p>{`Die `}<InlineCode mdxType="InlineCode">{`idb`}</InlineCode>{`-Bibliothek macht es einfach, Daten auf dem Endgerät zu speichern und sie später über
ein `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise"
      }}>{`Promise`}</a>{` abzurufen. Sie
bietet auch großartige `}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/"
      }}>{`TypeScript`}</a>{`-Typen und -Interfaces, welche die Erstellung
generischer Logik für die Interaktion mit dem Offline-Speicher unterstützen.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEDAgT/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAdy7EVQZZ//EABsQAAEEAwAAAAAAAAAAAAAAAAEAAgMTESFC/9oACAEBAAEFAqzmM7ricuGxNQC//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/AWR//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAgIDAQAAAAAAAAAAAAAAAAECERAhMWH/2gAIAQEABj8Cclw3JMuh+Z//xAAZEAEAAwEBAAAAAAAAAAAAAAABABExIUH/2gAIAQEAAT8hVIBGTlrbwl5fTsAU742aAp2UnW5//9oADAMBAAIAAwAAABBE3//EABcRAAMBAAAAAAAAAAAAAAAAAAABEUH/2gAIAQMBAT8Qdo2Yf//EABcRAAMBAAAAAAAAAAAAAAAAAAABEVH/2gAIAQIBAT8QWFZ//8QAHRAAAgMAAgMAAAAAAAAAAAAAAREAITFBUWFxgf/aAAgBAQABPxBxIBNhobVKGACZgRFI16NVtSiE3bfkTQGpg+YGA9cMfc1AjdVP/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Eine Frau sitzt auf dem Boden, umgeben von Pappkartons.",
          "title": "Eine Frau sitzt auf dem Boden, umgeben von Pappkartons.",
          "src": "/static/b7292d1bfd8148c226f26147ce4f48e8/e5166/pexels-rdne-stock-project-boxes.jpg",
          "srcSet": ["/static/b7292d1bfd8148c226f26147ce4f48e8/f93b5/pexels-rdne-stock-project-boxes.jpg 300w", "/static/b7292d1bfd8148c226f26147ce4f48e8/b4294/pexels-rdne-stock-project-boxes.jpg 600w", "/static/b7292d1bfd8148c226f26147ce4f48e8/e5166/pexels-rdne-stock-project-boxes.jpg 1200w", "/static/b7292d1bfd8148c226f26147ce4f48e8/b17f8/pexels-rdne-stock-project-boxes.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © RDNE Stock project / pexels.com`}</em></p>
    <p>{`Sehen wir uns an, wie wir eine Datenbank mit `}<InlineCode mdxType="InlineCode">{`idb`}</InlineCode>{` einrichten können. Danach zeige ich
euch einige Codebeispiele für wiederverwendbare generische Methoden zum Speichern und Abrufen von Daten.`}</p>
    <h2>{`Die Datenbank einrichten`}</h2>
    <p>{`Als erstes müsst ihr das Schema für die zu speichernden Daten definieren. Hier ist ein einfaches Beispiel:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`import { DBSchema } from "idb";

export interface MyAwesomeDB extends DBSchema {
    images: {
        value: MyImageData;
        key: string;
    };
    videos: {
        value: MyVideoData;
        key: string;
    };
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Ich empfehle euch, die DB-Operationen in einer eigenen Klasse zu bündeln. Diese Klasse hat allein den Zweck, mit
der Datenbank zu interagieren. Das macht euren Code einfacher zu lesen und zu verstehen. In einer Angular-Anwendung
würde ich eine `}<a parentName="p" {...{
        "href": "https://angular.io/guide/architecture-services"
      }}>{`Serviceklasse`}</a>{` wie diese erstellen:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`import { IDBPDatabase, openDB } from "idb";

@Injectable()
export class OfflineStorageService {
    private _databaseVersion = 1;
    // The promise returned when opening the IndexedDB with openDB() method.
    private _myDatabase$: Promise<IDBPDatabase<MyAwesomeDB>> | null = null;

    public init(): void {
        this._myDatabase$ = openDB<MyAwesomeDB>(
            "my-awesome-database",
            this._databaseVersion,
            {
                // Initialize objects in database if opened for the first time.
                upgrade: (database, oldVersion) => { ... },
            },
        );

        // Handle success and error case.
        this._myDatabase$.then(
            () => console.log("IndexedDB was successfully opened.""),
            () => this.onOpeningDatabaseFailed(),
        );
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Daten speichern`}</h2>
    <p>{`Nehmen wir an, ihr wollt mehrere Objekte in einem
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/IDBObjectStore"
      }}>{`bestimmten Objektspeicher`}</a>{`
der Datenbank ablegen. Dazu startet ihr eine `}<InlineCode mdxType="InlineCode">{`Transaktion`}</InlineCode>{` mit Lese- und Schreibzugriff
und speichert dann die Objekte. Für diesen Vorgang könnt ihr eine generische Methode mit den
Parametern `}<InlineCode mdxType="InlineCode">{`storeName`}</InlineCode>{` und `}<InlineCode mdxType="InlineCode">{`items`}</InlineCode>{` definieren:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`import { StoreNames, StoreValue } from "idb";

// Stores all items in the object store named "storeName".
private storeAllItemsInObjectStore<Name extends StoreNames<MyAwesomeDB>>(storeName: Name, items: StoreValue<MyAwesomeDB, Name>[]): void {
    this._myDatabase$?.then((database) => {
        const transaction = database.transaction(storeName, "readwrite");
        items.forEach((item) => transaction.store.add(item));
    });
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Die Methode verwendet die Typen `}<InlineCode mdxType="InlineCode">{`StoreNames`}</InlineCode>{` und `}<InlineCode mdxType="InlineCode">{`StoreValue`}</InlineCode>{` aus
der `}<ItalicText mdxType="ItalicText">{`idb-Bibliothek`}</ItalicText>{`. Diese stellen sicher, dass der an die Methode übergebene
Parameter `}<InlineCode mdxType="InlineCode">{`storeName`}</InlineCode>{` tatsächlich Teil der Datenbank ist und dass die `}<InlineCode mdxType="InlineCode">{`items`}</InlineCode>{`
die erwartete Datenstruktur haben. Hier ist ein Beispiel für die Verwendung dieser generischen Methode:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`public storeImages(items: MyImageData[]): void {
    this.storeAllItemsInObjectStore("images", items);
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Daten abrufen`}</h2>
    <p>{`Beim Abrufen von Daten aus der lokalen Datenbank ziehe ich es vor, das native `}<InlineCode mdxType="InlineCode">{`Promise`}</InlineCode>{` in
ein `}<InlineCode mdxType="InlineCode">{`Observable`}</InlineCode>{` umzuwandeln. Das erleichtert die Integration des Offline-Speichers in eine auf
Observables basierende Application-State-Logik (z.B. `}<a parentName="p" {...{
        "href": "https://ngrx.io/"
      }}>{`NgRx store`}</a>{`). Hier ist eine generische Methode,
die ein bestimmtes Element aus einem Objektspeicher, verpackt in ein Observable, zurückgibt:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`import { StoreKey, StoreNames, StoreValue } from "idb";
import { Observable, catchError, from, of, switchMap } from "rxjs";

// Retrieves the item identified via "itemKey" from the object store named "storeName". Resolves with undefined if no match is found, the database is unavailable, or there's an error.
private getItemFromObjectStore<Name extends StoreNames<MyAwesomeDB>>(
    storeName: Name,
    itemKey: StoreKey<MyAwesomeDB, Name>,
): Observable<StoreValue<MyAwesomeDB, Name> | undefined> {
    if (this._myDatabase$ === null) {
        return of(undefined);
    }

    return from(this._myDatabase$).pipe(
        switchMap((database) => from(database.get(storeName, itemKey))),
        catchError((error) => {
            console.error(error);
            return of(undefined);
        }),
    );
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Ihr könnt die generische Methode folgendermaßen verwenden:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`public getImage(imageId: string): Observable<MyImageData | undefined> {
    return this.getItemFromObjectStore("images", imageId);
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Viel Spaß mit `}<InlineCode mdxType="InlineCode">{`IndexedDB`}</InlineCode>{` und der `}<InlineCode mdxType="InlineCode">{`idb`}</InlineCode>{` Bibliothek!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      